import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import shuffle from 'lodash/shuffle'
import { graphql } from 'gatsby'

import VerticalCurve from 'components/VerticalCurve'
import Layout from 'components/layout'
import SEO from 'components/seo'
import { H, Wrapper, Grid } from 'components/common'
import Image from 'components/Image'
import CategoryList from 'components/CategoryList'
import { COLOR, FONT, GTR } from 'styles/tokens'
import { screenMax } from 'styles/helpers/responsive'

import FeaturedJobs from 'components/FeaturedJobs'
import Register from './Register'

const StyledList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: ${GTR.M};
`

const StyledListItem = styled.li`
  background-color: ${COLOR.BACKGROUND.PALE};
  padding: ${GTR.S};
  border-radius: ${GTR.L};
  display: inline-block;
  font-weight: ${FONT.WEIGHT.MEDIUM};
  text-align: center;
  width: calc(50% - ${GTR.XS});
  margin-top: ${GTR.S};
  font-size: ${FONT.SIZE.S};

  ${screenMax.s`
    width: 100%;
    font-size: ${FONT.SIZE.M};
  `}
`

const Category = ({
  pageContext: { seoTitle, seoDescription, title, about, roles, jobs },
  data: { image, teamImage },
}) => {
  const featuredJobs = shuffle(jobs).slice(0, 3)
  return (
    <Layout light>
      <SEO title={seoTitle} description={seoDescription} />
      <VerticalCurve>
        <Wrapper container>
          <Wrapper background={COLOR.WHITE} paddingFromL="XL" borderRadius="M">
            <H
              size="XL"
              centered
              margin={[0, 0, 'XL']}
              marginFromL={[0, 0, 'XXL']}
            >
              {title}
            </H>
            <Grid>
              <Grid.Item spanFromL={5}>
                {about.map((paragraph) => (
                  <p key={paragraph}>{paragraph}</p>
                ))}
              </Grid.Item>
              <Grid.Item spanFromL={6} startColumnFromL={7}>
                <Image
                  sizes={{ ...image.childImageSharp.fluid, aspectRatio: 3 / 2 }}
                />

                <StyledList>
                  {roles.map((role) => (
                    <StyledListItem key={role}>{role}</StyledListItem>
                  ))}
                </StyledList>
              </Grid.Item>
            </Grid>
          </Wrapper>
        </Wrapper>
      </VerticalCurve>
      <FeaturedJobs jobs={featuredJobs} background={COLOR.BACKGROUND.PALE} />
      <Register teamImage={teamImage} />
      <Wrapper background={COLOR.BACKGROUND.PALE}>
        <Wrapper container>
          <H size="M" centered margin={[0, 0, 'L']} marginFromM={[0, 0, 'XL']}>
            Explore other disciplines
          </H>
          <CategoryList wide />
        </Wrapper>
      </Wrapper>
    </Layout>
  )
}

Category.propTypes = {
  pageContext: PropTypes.shape({
    title: PropTypes.string.isRequired,
    about: PropTypes.arrayOf(PropTypes.string).isRequired,
    roles: PropTypes.arrayOf(PropTypes.string).isRequired,
    jobs: PropTypes.array.isRequired,
    seoTitle: PropTypes.string.isRequired,
    seoDescription: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    image: PropTypes.object.isRequired,
    teamImage: PropTypes.object.isRequired,
  }).isRequired,
}

export default Category

export const query = graphql`
  query HelpArticle($image: String!, $teamImage: String) {
    image: file(relativePath: { eq: $image }) {
      ...fluidImage
    }
    teamImage: file(relativePath: { eq: $teamImage }) {
      ...fluidImage
    }
  }
`
