import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { screenMax } from 'styles/helpers/responsive'
import { Link } from 'gatsby'

import PATHS from 'routes/paths'

import Image from 'components/Image'
import CurveWrapper from 'components/CurveWrapper'
import { Button, H, Grid, Wrapper } from 'components/common'
import { COLOR } from 'styles/tokens'

const StyledWrapper = styled(CurveWrapper)`
  color: ${COLOR.WHITE};
`

const StyledImageGridItem = styled(Grid.Item)`
  ${screenMax.s`
    display: none;
  `}
`

const Register = ({ teamImage }) => {
  return (
    <StyledWrapper background={COLOR.GREY.DARK} top={40}>
      <Grid container>
        <StyledImageGridItem spanFromM={6}>
          <Image
            sizes={{ ...teamImage.childImageSharp.fluid, aspectRatio: 3 / 2 }}
          />
        </StyledImageGridItem>
        <Grid.Item
          spanFromM={5}
          startColumnFromM={8}
          style={{ alignSelf: 'center' }}
        >
          <H size="L" color={COLOR.ACCENT.PRIMARY}>
            Find your future
          </H>
          <Wrapper margin={[0, 0, 'L']}>
            <p>We’ll keep you posted with all the latest roles</p>
          </Wrapper>
          <Button.Primary tag={Link} to={PATHS.REGISTER} wide stretchMobile>
            Register with us
          </Button.Primary>
        </Grid.Item>
      </Grid>
    </StyledWrapper>
  )
}

Register.propTypes = {
  teamImage: PropTypes.shape({
    childImageSharp: PropTypes.object.isRequired,
  }).isRequired,
}

export default Register
